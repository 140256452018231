import React from 'react';

function FillX({ level }) {
  const boxSize = 24; // Box size (24x24 pixels)
  const lineThickness = 2; // Thickness of the lines
  const lineLength = 18; // Length of the diagonal lines
  const offset = (boxSize - lineLength) / 2; // Offset to center the lines

  // Base style for the lines
  const lineStyle = {
    position: 'absolute',
    width: `${lineThickness}px`,
    height: `${lineLength}px`,
    background: '#333',
    transformOrigin: 'center center',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`, // Center both horizontally and vertically
  };

  const line1Full = {
    ...lineStyle,
    transform: `translate(-50%, -50%) rotate(45deg)`, // First diagonal
  };

  const line2Full = {
    ...lineStyle,
    transform: `translate(-50%, -50%) rotate(-45deg)`, // Second diagonal
  };

  const line1Half = {
    ...line1Full,
    height: `${lineLength / 2}px`, // Half the length for partial fills
    //transform: `translate(-50%, -50%) rotate(45deg)`,
    //top: `calc(50% - ${lineLength / 4}px)`, // Adjust the top offset for proper centering
    //right: `calc(50% - ${lineLength / 2}px)`,
  };

  const line2Half = {
    ...line2Full,
    height: `${lineLength / 2}px`, // Half the length for partial fills
    //top: `calc(50% - ${lineLength / 4}px)`, // Adjust the top offset for proper centering
  };

  switch (level) {
    case 'one-quarter':
      return (
        <div style={{ position: 'relative', width: `${boxSize}px`, height: `${boxSize}px` }}>
          <div style={line1Half} />
        </div>
      );
    case 'half':
      return (
        <div style={{ position: 'relative', width: `${boxSize}px`, height: `${boxSize}px` }}>
          <div style={line1Full} />
        </div>
      );
    case 'three-quarters':
      return (
        <div style={{ position: 'relative', width: `${boxSize}px`, height: `${boxSize}px` }}>
          <div style={line1Full} />
          <div style={line2Half} />
        </div>
      );
    case 'full':
      return (
        <div style={{ position: 'relative', width: `${boxSize}px`, height: `${boxSize}px` }}>
          <div style={line1Full} />
          <div style={line2Full} />
        </div>
      );
    default:
      return null;
  }
}

export default FillX;

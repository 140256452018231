import React, { useState, useEffect, useCallback } from 'react';
import BoxIcon from './components/BoxIcon/BoxIcon';
import { getDaysInMonth, getMonthName, getWeekdayName } from './utils/dateUtils';
import { db } from './utils/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import './styles/App.css';

import { 
  IoMoonOutline, 
  IoBarbellOutline, 
  IoNutritionOutline, 
  IoCheckmarkCircleOutline 
} from 'react-icons/io5';

const ICONS = [
  { 
    type: 'sleep', 
    icon: <IoMoonOutline />, 
    label: 'Sleep', 
    explanations: {
      none: '<6 hours',
      'one-quarter': '6 to 6.5 hours',
      half: '6.5 to 7 hours',
      'three-quarters': '7 to 7.5 hours',
      full: '>7.5 hours'
    }
  },
  { 
    type: 'exercise', 
    icon: <IoBarbellOutline />, 
    label: 'Exercise', 
    explanations: {
      none: 'no exercise',
      'one-quarter': '0.5 hr moderate',
      half: '1 hr moderate',
      'three-quarters': '0.5 hr vigorous or 1.5 hr moderate',
      full: '1 hr vigorous or 2 hr moderate'
    }
  },
  { 
    type: 'fruits', 
    icon: <IoNutritionOutline />, 
    label: 'Fruits/Vege', 
    explanations: {
      none: '<2 servings',
      'one-quarter': '2 servings',
      half: '3 servings',
      'three-quarters': '4 servings',
      full: '>= 5 servings'
    }
  },
  { 
    type: 'healthy', 
    icon: <IoCheckmarkCircleOutline />, 
    label: 'Healthy', 
    explanations: {
      none: 'unhealthy',
      'one-quarter': 'one meal partially healthy',
      half: 'one meal healthy',
      'three-quarters': 'one meal healthy, one meal partially healthy',
      full: 'two meals healthy'
    }
  },
];

function App() {
  const today = new Date();
  const todayString = today.toDateString(); 
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const [currentMonth, setCurrentMonth] = useState(today.getMonth()); // 0-based

  // monthData is an object keyed by "year-month", each value is an array of days for that month.
  const [monthData, setMonthData] = useState({});
  const [monthSelectorOpen, setMonthSelectorOpen] = useState(false);

  const daysInMonth = getDaysInMonth(currentYear, currentMonth);
  const yearMonthKey = `${currentYear}-${currentMonth}`;
  const days = monthData[yearMonthKey] || [];

  const loadMonthData = useCallback(async () => {
    const docRef = doc(db, "monthlyData", yearMonthKey);
    const docSnap = await getDoc(docRef);
    let loadedData = [];
    if (docSnap.exists()) {
      loadedData = docSnap.data().days || [];
    } else {
      // Initialize new data for this month if no existing record
      loadedData = Array.from({ length: daysInMonth }, (_, i) => {
        const date = new Date(currentYear, currentMonth, i + 1);
        return {
          date: date.toISOString(),
          highlight: '',
          weight: '',
          boxes: {
            sleep: 'none',
            exercise: 'none',
            fruits: 'none',
            healthy: 'none'
          }
        };
      });
    }

    setMonthData(prev => ({ ...prev, [yearMonthKey]: loadedData }));
  }, [currentYear, currentMonth, daysInMonth, yearMonthKey]);

  // Load data when month/year changes
  useEffect(() => {
    loadMonthData();
  }, [loadMonthData]);

  // Save data to Firestore whenever the array for the current month changes
  useEffect(() => {
    const saveData = async () => {
      if (days.length === daysInMonth) {
        await setDoc(doc(db, "monthlyData", yearMonthKey), {
          days
        });
      }
    };
    saveData();
  }, [days, yearMonthKey, daysInMonth]);

  const handleHighlightChange = (index, value) => {
    const updated = [...days];
    updated[index].highlight = value;
    setMonthData(prev => ({ ...prev, [yearMonthKey]: updated }));
  };

  const handleWeightChange = (index, value) => {
    const updated = [...days];
    updated[index].weight = value;
    setMonthData(prev => ({ ...prev, [yearMonthKey]: updated }));
  };

  const handleBoxChange = (index, boxType, fillLevel) => {
    const updated = [...days];
    updated[index].boxes[boxType] = fillLevel;
    setMonthData(prev => ({ ...prev, [yearMonthKey]: updated }));
  };

  const prevMonth = () => {
    const m = currentMonth - 1;
    if (m < 0) {
      setCurrentYear(prev => prev - 1);
      setCurrentMonth(11);
    } else {
      setCurrentMonth(m);
    }
  };

  const nextMonth = () => {
    const m = currentMonth + 1;
    if (m > 11) {
      setCurrentYear(prev => prev + 1);
      setCurrentMonth(0);
    } else {
      setCurrentMonth(m);
    }
  };

  const handleMonthYearSelect = (year, month) => {
    setCurrentYear(year);
    setCurrentMonth(month);
    setMonthSelectorOpen(false);
  };

  const monthOptions = Array.from({ length: 12 }, (_, m) => ({ monthIndex: m, name: getMonthName(m) }));

  // Tooltip state for headers
  const [headerTooltip, setHeaderTooltip] = useState({ visible: false, x: 0, y: 0, explanations: {} });

  const showHeaderTooltip = (e, explanations) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const scrollX = window.scrollX;
    const scrollY = window.scrollY;

    setHeaderTooltip({
      visible: true,
      x: rect.left + scrollX + rect.width / 2,
      y: rect.bottom + scrollY + 8,
      explanations
    });
  };

  const hideHeaderTooltip = () => {
    setHeaderTooltip({ visible: false, x: 0, y: 0, explanations: {} });
  };

  const isSameMonthYear = (today.getFullYear() === currentYear && today.getMonth() === currentMonth);

  return (
    <div className="app-container">
      <header className="header">
        <button className="nav-button" onClick={prevMonth}>&lt;</button>
        <div className="month-display" onClick={() => setMonthSelectorOpen(!monthSelectorOpen)}>
          {getMonthName(currentMonth)} {currentYear}
          {monthSelectorOpen && (
            <div className="month-selector">
              {monthOptions.map((opt) => (
                <div 
                  key={opt.monthIndex} 
                  className="month-option" 
                  onClick={(e) => { 
                    e.stopPropagation();
                    handleMonthYearSelect(currentYear, opt.monthIndex); 
                  }}
                >
                  {opt.name} {currentYear}
                </div>
              ))}
            </div>
          )}
        </div>
        <button className="nav-button" onClick={nextMonth}>&gt;</button>
      </header>

      <div className="tracking-table">
        <div className="table-header">
          <div className="day-header">Day</div>
          <div className="highlight-header">Highlight</div>
          <div className="weight-header">Weight</div>
          {ICONS.map(iconItem => (
            <div 
              key={iconItem.type} 
              className="header-icon-cell"
              onMouseEnter={(e) => showHeaderTooltip(e, iconItem.explanations)}
              onMouseLeave={hideHeaderTooltip}
            >
              {iconItem.icon}
            </div>
          ))}
        </div>
        <div className="table-body">
          {days.map((dayData, index) => {
            const dateObj = new Date(dayData.date);
            const weekday = getWeekdayName(dateObj);
            const dayNum = dateObj.getDate();
            const isToday = isSameMonthYear && dateObj.toDateString() === todayString;

            const highlightPlaceholder = isToday ? "Add highlight..." : "";
            const weightPlaceholder = isToday ? "Weight" : "";

            return (
              <div className={`row ${isToday ? 'today-row' : ''}`} key={index}>
                <div className="day-cell">
                  <span className="weekday">{weekday}</span>
                  <span className="daynum">{dayNum}</span>
                </div>
                <div className="highlight-cell">
                  <input 
                    type="text" 
                    className="highlight-input"
                    placeholder={highlightPlaceholder}
                    value={dayData.highlight}
                    onChange={(e) => handleHighlightChange(index, e.target.value)}
                  />
                </div>
                <div className="weight-cell">
                  <input 
                    type="number"
                    className="weight-input"
                    placeholder={weightPlaceholder}
                    value={dayData.weight}
                    onChange={(e) => handleWeightChange(index, e.target.value)}
                  />
                </div>
                {ICONS.map(iconItem => (
                  <div className="icon-box-cell" key={iconItem.type}>
                    <BoxIcon
                      label={iconItem.label}
                      fillLevel={dayData.boxes[iconItem.type]}
                      onSelect={(level) => handleBoxChange(index, iconItem.type, level)}
                      showIcon={false}
                    />
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
      {headerTooltip.visible && (
        <div 
          className="header-tooltip"
          style={{ 
            position: 'fixed', 
            left: headerTooltip.x, 
            top: headerTooltip.y, 
            transform: 'translate(-50%, 0)', 
            background: '#fff',
            border: '1px solid #ccc',
            color: '#333', 
            padding: '8px 10px',
            borderRadius: '4px',
            fontSize: '14px',
            zIndex: 9999,
            width: '250px',
            lineHeight: '1.4',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
          }}
        >
          {Object.entries(headerTooltip.explanations).map(([key, val]) => (
            <div key={key}><b>{key}</b>: {val}</div>
          ))}
        </div>
      )}
    </div>
  );
}

export default App;

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyB9dqmFUfRL7W0vUMAe3oZKsNQ04czKkao",
    authDomain: "dailytracker-4db3a.firebaseapp.com",
    projectId: "dailytracker-4db3a",
    storageBucket: "dailytracker-4db3a.firebasestorage.app",
    messagingSenderId: "524505598821",
    appId: "1:524505598821:web:535a3e460f0f1b9ace2f8c",
    measurementId: "G-PZ3K3BYCTQ"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };

export function getDaysInMonth(year, month) {
    return new Date(year, month + 1, 0).getDate();
  }
  
  export function getMonthName(monthIndex) {
    const months = ["January", "February", "March", "April", "May", "June", 
                    "July", "August", "September", "October", "November", "December"];
    return months[monthIndex];
  }
  
  export function getWeekdayName(date) {
    const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return weekdays[date.getDay()];
  }
  